import { Timestamp } from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";

type Props = {
  position: string;
  dateStart: Timestamp;
  dateEnd: Timestamp;
  companyName: string;
  imagePath: string;
  technologies: Array<string>;
  description: string;
};

const ExperienceCard: React.FC<Props> = (props) => {
  const storage = getStorage();

  const [companyImage, setCompanyImage] = useState("");
  const [techs, setTechs] = useState<string[]>([]);

  useEffect(() => {
    getDownloadURL(ref(storage, props.imagePath)).then((result) => {
      setCompanyImage(result);
    });
    if (Array.isArray(props.technologies) && props.technologies.length > 0) {
      props.technologies.map((el) => {
        getDownloadURL(ref(storage, el)).then((result) => {
          setTechs((techs)=>[...techs,result])
        });
      });
    }
  }, []);

  if (techs.length === 0) {
    return <>Loading...</>;
  }
  return (
    <article className="flex drop-shadow-xl flex-col rounded-3xl items-center space-y-0 flex-shrink-0 w-72  md:w-[600px] xl:w-[700px] snap-center bg-lbrown bg-gradient-to-bl from-dbrown/30 to-transparent p-5 md:p10 hover:opacity-100 opacity-100 cursor-pointer transition-opacity duration-200 ">
      <motion.img
        initial={{ opacity: 0, y: -100 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 1.2 }}
        className="w-28 h-28 rounded-full xl:w-[150px] xl:h-[150px] mb-2 object-contain object-center"
        src={companyImage}
        alt=""
      />
      <div className="w-full px-0 md:px-10">
        <div className=" md:flex md:justify-between items-center">
          <div>
            <h4 className="text-lg md:text-3xl font-light text-black">
              {props.position}
            </h4>
            <p className="font-bold text-md md:text-2xl  mt-1 text-dbrown">
              {props.companyName}
            </p>
            <div className="flex space-x-2 my-2">
              {techs.map((tech, index) => (
                <img
                  key={index}
                  className="h-6 w-6 md:h-10 md:w-10 rounded-full object-contain"
                  src={tech}
                  alt=""
                />
              ))}
            </div>
          </div>
        </div>
        <p className="uppercase py-2 md:py-5 text-gray-500 text-sm md:text-lg">
        {props.dateStart.toDate().toLocaleDateString("en-US",{year: 'numeric', month: 'long', day: 'numeric'})} - {props.dateEnd.toDate().getTime() > new Date().getTime() ? "Present" : props.dateEnd.toDate().toLocaleDateString("en-US",{year: 'numeric', month: 'long', day: 'numeric'})}
        {props.dateEnd.toDate().getTime() > new Date().getTime() ? "" : (props.dateEnd.toDate().getTime() - props.dateStart.toDate().getTime() > 0 ? ` (${Math.floor((props.dateEnd.toDate().getTime() - props.dateStart.toDate().getTime()) / (1000 * 60 * 60 * 24 * 30))} months)` : "")}
        </p>
      </div>
      <p className="md:px-10 text-black space-y-2 text-justify ml-0 text-sm md:text-lg">
        {props.description}
      </p>
    </article>
  );
};

export { ExperienceCard };
