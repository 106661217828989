import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Index from "./Pages/Index";
import { useEffect } from "react";

const ExternalRedirect = ({ to }) => {
  useEffect(() => {
    window.location.href = to;
  }, [to]);

  return null;
};

function App() {
  return (
    <div
      className="h-screen snap-y snap-mandatory
    overflow-y-scroll overflow-x-hidden z-0 scrollbar-thin scrollbar-track-gray-400/20 scrollbar-thumb-navbar"
    >
      <BrowserRouter>
      <Routes>
        <Route path="/babylonthesis" Component={() => <ExternalRedirect to="https://bachelorsthesis-52ef2.web.app/" />} />
        <Route path="/unitythesis" Component={() => <ExternalRedirect to="https://bachelorsthesis-52ef2.web.app/" />} />
        <Route path="/" Component={() =>  <Index />} />
      </Routes>
       
      </BrowserRouter>
    </div>
  );
}

export default App;
