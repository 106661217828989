import { useForm, SubmitHandler } from "react-hook-form";

const ContactMe:React.FC = () =>{
    
    const { register, handleSubmit } = useForm();
    const onSubmit = (formData) => {
        window.location.href = `mailto:daniel12094@gmail.com?subject=${formData.subject}&body=Hi, my name is ${formData.name}.${formData.message}`;
      };
        return (
            <div className="h-screen flex relative flex-col text-center md:text-left md:flex-row max-w-7xl px-10 justify-evenly mx-auto items-center snap-center">
            <h3 className="absolute top-20 md:top-24 uppercase tracking-[20px] text-dbrown text-xl md:text-2xl">
              Contact
            </h3>
            <div className="flex flex-col space-y-4 md:space-y-5 lg:space-y-6 xl:space-y-6 2xl:space-y-10">
              <h4 className="text-xl md:text-2xl lg:text-3xl 2xl:text-4xl font-semibold text-center">
                If you have any questions.{" "}
                <span className="decoration-darkGreen/50 underline">Reach out to me!</span>
              </h4>
      
              <div className="space-y-1 md:space-y-3 lg:space-y-3 xl:space-y-3 2xl:space-y-5">
                <div className="flex items-center space-x-5 justify-center">
                  
                  <p className="text-lg md:text-2xl lg:text-2xl">+36 30 535 8964</p>
                </div>
                <div className="flex items-center space-x-5 justify-center">
                 
                  <p className="text-lg md:text-2xl lg:text-2xl">
                    daniel12094@gmail.com
                  </p>
                </div>
                <div className="flex items-center space-x-5 justify-center">
                  
                  <p className="text-lg md:text-2xl lg:text-2xl">
                    Budapest, Hungary
                  </p>
                </div>
              </div>
      
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col space-y-2 w-80 md:w-fit mx-auto"
              >
                <div className="md:flex md:space-x-2 space-y-2 md:space-y-0 ">
                  <input
                    {...register("name")}
                    placeholder="Name"
                    className="contactInput w-80 md:w-auto"
                    type="text"
                  />{" "}
                  <input
                    {...register("email")}
                    placeholder="Email"
                    className="contactInput w-80 md:w-auto"
                    type="email"
                  />
                </div>
                <input
                  {...register("subject")}
                  placeholder="Subject"
                  className="contactInput "
                  type="text"
                />
                <textarea
                  {...register("message")}
                  placeholder="Message"
                  className="contactInput"
                />
                <button className="bg-dbrown py-3 md:py-5 px-10 rounded-lg text-white font-bold text-lg">
                  {" "}
                  Submit
                </button>
              </form>
            </div>
          </div>
        )
}

export default ContactMe;

