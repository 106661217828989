import Hero from "../Components/Hero";
import { Skills } from "../Components/Skills";
import Projects from "../Components/Projects";
import { WorkExperience } from "../Components/WorkExperience";
import ContactMe from "../Components/ContactMe";
import AboutMe from "../Components/AboutMe";
import NavBar from "../Components/NavBar";
import { db } from "../firebase-config";
import { Timestamp, collection, getDocs } from "firebase/firestore";
import { useEffect, useState } from "react";

export type Experience = {
  company:string,
  dateStart:Timestamp,
  dateEnd:Timestamp,
  description:string,
  image:string,
  position:string,
  technologies:Array<string>
}

export type Project = {
  name:string,
  description:string,
  image:string,
  technologies:Array<string>
}

export type Skill = {
  imagePath:string,
  percent:number,
}

const Index: React.FC = () => {
  const [profilePic, setProfilePic] = useState("");
  const [aboutMeText, setAboutMeText] = useState("");
  const [experiences, setExperiences] = useState<Experience[]>([])
  const [projects, setProjects] = useState<Project[]>([])
  const [skills, setSkills] = useState<Skill[]>([])
  const [cv, setCV] = useState<string>("")

  useEffect(() => {
    getDocs(collection(db, "Content")).then((result) => {
      setProfilePic(result.docs[0].get("images")[1]);
      setAboutMeText(result.docs[0].get("aboutMeText"))
      setCV(result.docs[0].get("cv"))
    });
    getDocs(collection(db,"Experiences")).then((result) =>{
      const experiencesData: Experience[] = [];
    result.forEach((el) => {
      experiencesData.push(el.data() as Experience);
    });
    setExperiences(experiencesData);
    });
    getDocs(collection(db,"Projects")).then((result) =>{
      const projectsData: Project[] = [];
    result.forEach((el) => {
      projectsData.push(el.data() as Project);
    });
    setProjects(projectsData);
    })
    getDocs(collection(db,"Skills")).then((result) =>{
      const skills: Skill[] = [];
    result.forEach((el) => {
      skills.push(el.data() as Skill);
      console.log(skills[0].imagePath)
    });
    setSkills(skills);
    })
    
  },[]);

  if (profilePic === "" || experiences === null) {
    return <>Loading..</>;
  }
  return (
    <div>
      <NavBar />
      <Hero image={profilePic} />
      <AboutMe image={profilePic} text={aboutMeText} cv={cv}/>
      <WorkExperience experiences={experiences}/>
      <Skills skills={skills} />
      <Projects projects={projects}/>
      <ContactMe />
    </div>
  );
};

export default Index;
