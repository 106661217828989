import {motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';

type Props = {
  image: string;
  text:string;
  cv:string;
};

const AboutMe:React.FC<Props> = (props) => {
  const storage = getStorage();
  const [aboutMeImage,setAboutMeImage] = useState("")

  useEffect(() => {
    getDownloadURL(ref(storage, props.image)).then((result) => {
      setAboutMeImage(result);
    });
  }, []);

return(
    <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.5 }}
        className="snap-center flex flex-col relative h-screen text-center md:text-left md:flex-row max-w-7xl px-10 justify-evenly mx-auto items-center"
        id="hero"
      >
        <h3 className="absolute top-20 md:top-20 uppercase tracking-[20px] text-dbrown text-xl md:text-2xl">
          About
        </h3>

        <motion.img
          initial={{
            x: -200,
            opacity: 0,
          }}
          transition={{
            duration: 1.2,
          }}
          whileInView={{
            x: 0,
            opacity: 1,
          }}
          viewport={{ once: true }}
          className="md:mb-0 flex-shrink-0 hidden md:flex w-0 h-0 rounded-full object-cover md:rounded-lg md:w-64 md:h-96 xl:w-[500px] xl:h-[600px]"
          src={aboutMeImage}
        />
        <div className="space-y-5 md:space-y-10 px-0 md:px-10">
          <h4 className="text-xl md:text-4xl font-semibold">
            Here is a little background
          </h4>
          <p className="text-sm md:text-lg lg:text-lg text-justify">
            {props.text}
          </p>
          <a
            className="underline decoration-navbar"
            href={props.cv}
            target="_blank"
          >
            <b>click here</b>
          </a>
          !
        </div>
      </motion.div>

)



}

export default AboutMe;